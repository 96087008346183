import React from 'react';
import { GET_NOT_FOUND_PAGE } from 'api/pages/queries';
import {
  PageQuery,
  PageQueryVariables,
  PageIdType,
  NotFoundPageFragmentsFragment,
} from 'types';
import { createApolloClient } from 'lib/apolloClient';
import { PageWrapper, SeoHead } from 'layouts';
import { NotFound, NotFoundProps } from 'components';
import { GetStaticProps, GetStaticPropsContext } from 'next';
import { getWPLocale, homepageUrls, logger } from 'utils';
import { EventIds } from '../lib';
import { ParsedUrlQuery } from 'querystring';
import { PreviewDataProps } from '../lib/previewApi';
import { PreviewModeBanner } from '../components/PreviewBanner';

export interface NotFoundPageProps {
  isPreviewEnabled: boolean;
  data: NotFoundPageFragmentsFragment | null;
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({
  data,
  isPreviewEnabled,
}) => {
  return (
    <PageWrapper headerColor="white" translations={homepageUrls}>
      <SeoHead seoHead={data?.seo?.fullHead} />
      {isPreviewEnabled && <PreviewModeBanner />}
      <NotFound data={data} />
    </PageWrapper>
  );
};

const getNotFoundPageData = async (
  props: GetStaticPropsContext<ParsedUrlQuery, PreviewDataProps>
) => {
  const isPreview = !!props.preview;
  const previewToken =
    isPreview && props.previewData?.token ? props.previewData?.token : null;
  // Create new apollo client instead of calling `initializeApolloClient` which returns a shared one.
  // We do that to avoid caching all posts data in apollo cache, which is then sent to client
  const apolloClient = createApolloClient();

  const { data: pageData, error } = await apolloClient.query<
    PageQuery,
    PageQueryVariables
  >({
    query: GET_NOT_FOUND_PAGE,
    variables: {
      asPreview: isPreview,
      id: isPreview ? `${props.previewData?.id}` : '/not-found',
      idType: isPreview ? PageIdType.DatabaseId : PageIdType.Uri,
    },
    errorPolicy: 'all',
    ...(previewToken ? { context: { previewToken } } : {}),
  });

  const currentPageLocale = pageData?.page?.locale?.locale;
  const wpLocale = getWPLocale(props.locale);

  // find translation is such way because for SEO purpose we need to keep locale as "fr" (from next.config.js) instead of "fr_FR"
  const data =
    wpLocale === currentPageLocale
      ? pageData?.page
      : pageData?.page?.translated?.find(
          (item) => item?.locale?.locale === wpLocale
        ) || pageData?.page;

  return {
    data: data ?? null,
    error,
  };
};

export const getStaticProps: GetStaticProps<
  NotFoundProps,
  ParsedUrlQuery,
  PreviewDataProps
> = async (props) => {
  const { data, error } = await getNotFoundPageData(props);

  if (error) {
    logger.error(
      'Getting error on not found page fetch',
      EventIds.NotFoundPageError,
      error
    );
  }

  if (data === null && !error) {
    logger.error(
      'Not found page data is empty',
      EventIds.NotFoundPageError,
      undefined,
      { locale: props.locale }
    );
  }

  return {
    props: {
      data: data ?? null,
      isPreviewEnabled: !!props.preview,
    },
    revalidate: 600, // In seconds
  };
};

export default NotFoundPage;
